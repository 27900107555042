// noinspection JSUnusedGlobalSymbols

export type IconName = keyof typeof IconRegistry;

export const IconRegistry = {
  // Custom
  teamfightHexes: () => import(/* webpackPrefetch: true */ "./IconComponents/IconTeamfightHexes.vue"),

  // Misc.
  attackDamage: () => import(/* webpackPrefetch: true */ "./IconComponents/IconAttackDamage.vue"),
  attackSpeed: () => import(/* webpackPrefetch: true */ "./IconComponents/IconAttackSpeed.vue"),
  dps: () => import(/* webpackPrefetch: true */ "./IconComponents/IconDPS.vue"),

  // BoxIcons
  asterisk: () => import(/* webpackPrefetch: true */ "./IconComponents/IconAsterisk.vue"),
  aToZ: () => import(/* webpackPrefetch: true */ "./IconComponents/IconAToZ.vue"),
  backpack: () => import(/* webpackPrefetch: true */ "./IconComponents/IconBackpack.vue"),
  block: () => import(/* webpackPrefetch: true */ "./IconComponents/IconBlock.vue"),
  bugAlt: () => import(/* webpackPrefetch: true */ "./IconComponents/IconBugAlt.vue"),
  bullseye: () => import(/* webpackPrefetch: true */ "./IconComponents/IconBullseye.vue"),
  camera: () => import(/* webpackPrefetch: true */ "./IconComponents/IconCamera.vue"),
  caretRight: () => import(/* webpackPrefetch: true */ "./IconComponents/IconCaretRight.vue"),
  chevronDown: () => import(/* webpackPrefetch: true */ "./IconComponents/IconChevronDown.vue"),
  chevronRight: () => import(/* webpackPrefetch: true */ "./IconComponents/IconChevronRight.vue"),
  chevronUp: () => import(/* webpackPrefetch: true */ "./IconComponents/IconChevronUp.vue"),
  coin: () => import(/* webpackPrefetch: true */ "./IconComponents/IconCoin.vue"),
  copy: () => import(/* webpackPrefetch: true */ "./IconComponents/IconCopy.vue"),
  copyAlt: () => import(/* webpackPrefetch: true */ "./IconComponents/IconCopyAlt.vue"),
  data: () => import(/* webpackPrefetch: true */ "./IconComponents/IconData.vue"),
  discord: () => import(/* webpackPrefetch: true */ "./IconComponents/IconDiscord.vue"),
  download: () => import(/* webpackPrefetch: true */ "./IconComponents/IconDownload.vue"),
  droplet: () => import(/* webpackPrefetch: true */ "./IconComponents/IconDroplet.vue"),
  globe: () => import(/* webpackPrefetch: true */ "./IconComponents/IconGlobe.vue"),
  gridAlt: () => import(/* webpackPrefetch: true */ "./IconComponents/IconGridAlt.vue"),
  hash: () => import(/* webpackPrefetch: true */ "./IconComponents/IconHash.vue"),
  heart: () => import(/* webpackPrefetch: true */ "./IconComponents/IconHeart.vue"),
  hide: () => import(/* webpackPrefetch: true */ "./IconComponents/IconHide.vue"),
  home: () => import(/* webpackPrefetch: true */ "./IconComponents/IconHome.vue"),
  hot: () => import(/* webpackPrefetch: true */ "./IconComponents/IconHot.vue"),
  infoCircle: () => import(/* webpackPrefetch: true */ "./IconComponents/IconInfoCircle.vue"),
  lock: () => import(/* webpackPrefetch: true */ "./IconComponents/IconLock.vue"),
  magnifyingGlass: () => import(/* webpackPrefetch: true */ "./IconComponents/IconMagnifyingGlass.vue"),
  meteor: () => import(/* webpackPrefetch: true */ "./IconComponents/IconMeteor.vue"),
  pencil: () => import(/* webpackPrefetch: true */ "./IconComponents/IconPencil.vue"),
  plus: () => import(/* webpackPrefetch: true */ "./IconComponents/IconPlus.vue"),
  refresh: () => import(/* webpackPrefetch: true */ "./IconComponents/IconRefresh.vue"),
  reset: () => import(/* webpackPrefetch: true */ "./IconComponents/IconReset.vue"),
  save: () => import(/* webpackPrefetch: true */ "./IconComponents/IconSave.vue"),
  search: () => import(/* webpackPrefetch: true */ "./IconComponents/IconSearch.vue"),
  shield: () => import(/* webpackPrefetch: true */ "./IconComponents/IconShield.vue"),
  sortAlt: () => import(/* webpackPrefetch: true */ "./IconComponents/IconSortAlt.vue"),
  sortDown: () => import(/* webpackPrefetch: true */ "./IconComponents/IconSortDown.vue"),
  sortUp: () => import(/* webpackPrefetch: true */ "./IconComponents/IconSortUp.vue"),
  spreadsheet: () => import(/* webpackPrefetch: true */ "./IconComponents/IconSpreadsheet.vue"),
  star: () => import(/* webpackPrefetch: true */ "./IconComponents/IconStar.vue"),
  trafficBarrier: () => import(/* webpackPrefetch: true */ "./IconComponents/IconTrafficBarrier.vue"),
  trash: () => import(/* webpackPrefetch: true */ "./IconComponents/IconTrash.vue"),
  trophy: () => import(/* webpackPrefetch: true */ "./IconComponents/IconTrophy.vue"),
  upArrow: () => import(/* webpackPrefetch: true */ "./IconComponents/IconUpArrow.vue"),
  x: () => import(/* webpackPrefetch: true */ "./IconComponents/IconX.vue"),
  zap: () => import(/* webpackPrefetch: true */ "./IconComponents/IconZap.vue"),
};