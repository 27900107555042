<template>
  <div :class="['teamfight-notification', type]">
    <div class="highlight"/>

    <div class="title">
      <TeamfightIcon v-if="icon" :name="icon" size="2.4rem"/>
      <span>{{ type === 'error' ? "Look's like you found a bug!" : title }}</span>

      <div class="close">
        <TeamfightIcon
            name="x"
            size="2.4rem"
            @click="onClose"
        />
      </div>
    </div>

    <div class="text" v-html="message"/>

    <div v-if="link" class="actions">
      <NuxtLink :href="link" external target="_blank">
        <TeamfightButton>
          {{ CTA ?? 'Go There' }}
        </TeamfightButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IconName } from "~/components/Teamfight/Icon/IconRegistry";

interface Props {
  type: 'success' | 'warn' | 'error' | 'discord';
  title: string;
  text: string;
  onClose: () => void;
  link?: string;
  CTA?: string;
  icon?: IconName;
}

const props = defineProps<Props>();

const message = computed(() => {
  if (props.type === 'error') {
    return `
    <div class="notification-message">${props.title}: ${props.text}</div>
    </br>
    Please contact an admin on our
    <a class="discord" href="https://discord.gg/3gDXwZ8pG9" external target="_blank">Discord</a>
    so we can squash this bug ASAP!
  `;
  }

  return props.text;
});
</script>

<style lang="scss">
.teamfight-notification {
  position: relative;
  margin: 2rem;
  border-radius: var(--BorderRadius-S);
  padding: 1rem;
  font-size: 1.2rem;
  color: #ffffff;
  fill: #ffffff;
  border-left: 0.6rem solid transparent;
  box-shadow: 0.2rem 0.6rem 0.8rem rgba(0, 0, 0, 0.1);
  background-color: #181917;

  .text {
    margin: 1rem 0;
  }

  .discord {
    color: #7289da;
    font-weight: var(--FontWeight-Bold);
    transition: color 0.25s ease;

    &:hover {
      color: #93a7ee;
    }
  }

  .title {
    font-weight: var(--FontWeight-Bold);
    display: flex;
    align-items: center;
    gap: 1rem;

    span {
      flex: 1;
    }

    .close {
      cursor: pointer;
      opacity: 0.4;
      transition: opacity 0.25s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  &.success {
    border-left-color: #42a85f;
    fill: #42a85f;

    .title {
      color: #42a85f;
    }
  }

  &.warn {
    border-left-color: #f48a06;
    fill: #f48a06;

    .title {
      color: #f48a06;
    }
  }

  &.error {
    border-left-color: #e54d42;
    fill: #e54d42;

    .title {
      color: #e54d42;
    }

    .notification-message {
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }
  }

  &.discord {
    border-left-color: #7289da;
    fill: #7289da;

    .title {
      color: #7289da;
    }

    button {
      color: #181917;
      background-color: #7289da;
    }
  }
}
</style>