<template>
  <template v-if="error.statusCode === 404">
    <div id="app" ref="appRef">
      <TeamfightNotificationsHandler/>

      <NuxtLayout>
        <ViewWrapper :backdropImage="getCDNImageUrl('/TFTSet3_Wallpaper.jpeg')">
          <div style="flex: 1; display: flex; align-items: center; justify-content: center;">
            <EmoteNotice
                emote="Does_Not_Compute_Emote.webp"
                text="Check the URL and try again."
                title="404 - Looks like you've been tricked!"
            />
          </div>
        </ViewWrapper>
      </NuxtLayout>
    </div>
  </template>

  <template v-else>
    <ViewWrapper :backdropImage="getCDNImageUrl('/TFTSet12_Wallpaper.jpg')" height="100vh" hideAd>
      <div class="maintenance">
        <MaintenanceNotice/>

        <div v-if="isDev || userStore.isAdmin" class="maintenance__dev">
          <h2 class="maintenance__dev-message">
            {{ error.statusCode }} - {{ error.message }}
          </h2>

          <div class="maintenance__dev-stack" v-html="error.stack"/>
        </div>
      </div>
    </ViewWrapper>
  </template>
</template>

<script lang="ts" setup>
import { NuxtError } from "#app";
import MaintenanceNotice from "~/components/MaintenanceNotice.vue";
import { useUserStore } from "~/stores/UserStore";

interface Props {
  error: NuxtError;
}

defineProps<Props>();

const userStore = useUserStore();

const config = useRuntimeConfig();
const isDev = computed(() => config.public.environment === 'development');

</script>

<style lang="scss" scoped>
.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  color: white;

  h1 {
    margin-top: 3rem;
  }

  .maintenance__dev {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--UI-Secondary);
    padding: 2rem;
    border: 0.1rem solid var(--UI-Border);
    border-radius: var(--BorderRadius-S);

    .maintenance__dev-status {

    }

    .maintenance__dev-message {
      font-weight: var(--FontWeight-Bold);
    }

    .maintenance__dev-stack {

    }
  }
}
</style>