<template>
  <ClientOnly>
    <div
        v-if="userStore.isAdmin && (userStore.adminMode || alwaysShow)"
        :class="['admin-only', { '--subtle': subtle, '--constant': alwaysShow }]"
        :style="styles"
    >
      <div class="admin-only__icon">
        <TeamfightIcon name="hide" size="1rem"/>
      </div>
      <slot/>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useUserStore } from "~/stores/UserStore";
import { StyleValue } from "@vue/runtime-dom";

interface Props {
  alwaysShow?: boolean;
  subtle?: boolean;
  styles?: StyleValue;
}

withDefaults(defineProps<Props>(), {
  alwaysShow: false,
  subtle: false
});

const userStore = useUserStore();
</script>

<style lang="scss" scoped>
.admin-only {
  display: flex;
  position: relative;
  outline: 1px dotted rgba(241, 93, 59, .4);
  outline-offset: 0.5rem;
  opacity: 0.4;
  transition: all 0.25s ease;

  &:hover {
    opacity: 1;
  }

  .admin-only__icon {
    position: absolute;
    top: -1rem;
    right: -0.8rem;
    fill: var(--Color-Orange);
  }

  &.--constant {
    opacity: 1;
  }

  &.--subtle {
    outline: none;

    .admin-only__icon {
      display: none;
    }
  }
}
</style>