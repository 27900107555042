import { RiotPlatformRegion } from "~/types/riot";

export const PATCH_TIMESTAMPS: { [patch: string]: number } = {
  // Set 5.5
  "11.18": 1631084400,
  "11.19": 1632304800,
  "11.20": 1633514400,
  "11.21": 1634724000,

  // Set 6
  "11.22": 1635933600,
  "11.23": 1637143200,
  "11.24": 1638957600,            // Dec 8, 2021
  "12.1": 1641376800,             // Jan 5, 2022
  "12.2": 1642672800,             // Jan 20, 2022
  "12.3": 1643796000,             // Feb 2, 2022

  // Set 6.5
  "12.4": 1645005600,             // Feb 16, 2022
  "12.5": 1646215200,             // March 2, 2022
  "12.6": 1648634400,             // March 30, 2022
  "12.7": 1649844000,             // April 13, 2022
  "12.8": 1651053600,             // April 27, 2022
  "12.9": 1652263200,             // May 11, 2022
  "12.10": 1653472800,            // May 25, 2022

  // Set 7
  "12.11": 1654682400,            // June 8, 2022
  "12.12": 1655978400,            // June 23, 2022
  "12.13": 1657706400,            // July 13, 2022
  "12.14": 1658916000,            // July 27, 2022
  "12.15": 1660125600,            // Aug 10, 2022
  "12.16": 1661335200,            // Aug 24, 2022

  // Set 7.5
  "12.17": 1662631200,            // Sept 8, 2022
  "12.18": 1663754400,            // Sept 21, 2022
  "12.19": 1664964000,            // Oct 5, 2022
  "12.20": 1666173600,            // Oct 19, 2022
  "12.21": 1667383200,            // Nov 2, 2022
  "12.22": 1668592800,            // Nov 16, 2022

  // Set 8
  "12.23": 1670407200,            // Dec 7, 2022
  "13.1": 1673431200,	            // Wednesday, Jan 11, 2023
  // "13.2": 1674640800, - Cancelled
  "13.3": 1675850400,	            // Wednesday, Feb 8, 2023
  "13.4": 1677146400,	            // Thursday, Feb 23, 2023
  "13.5": 1678269600,	            // Wednesday, March 8, 2023

  // Set 8.5
  "13.6": 1679479200,	            // Wednesday, March 22, 2023
  "13.7": 1680688800,	            // Wednesday, April 5, 2023
  "13.8": 1681898400,	            // Wednesday, April 19, 2023
  "13.9": 1683108000,	            // Wednesday, May 3, 2023
  "13.10": 1684317600,	          // Wednesday, May 17, 2023
  "13.11": 1685613600,	          // Thursday, June 1, 2023

  // Set 9
  "13.12": 1686736800,	          // Wednesday, June 14, 2023
  "13.13": 1687946400,	          // Wednesday, June 28, 2023
  "13.13b": 1688122800,	          // Friday, June 30, 2023
  "13.13c": 1689033600,	          // Tuesday, July 11, 2023
  "13.14": 1689760800,	          // Wednesday, July 19, 2023
  "13.14b": 1689890400,           // Friday, July 21, 2023
  "13.15": 1690970400,	          // Wednesday, Aug 2, 2023
  "13.16": 1692172800,	          // Wednesday, Aug 16, 2023
  "13.16b": 1692824400,           // Wednesday, Aug 23, 2023
  "13.17": 1693346400,	          // Wednesday, Aug 30, 2023

  // Set 9.5
  "13.18": 1694602800,	          // Wednesday, Sept 13 2023 - 11AM GMT
  "13.18b": 1694811600,           // Friday, Sept 15 2023 - 9PM GMT
  "13.19": 1695812400,	          // Wednesday, Sept 27 2023 - 11AM GMT
  "13.19b": 1695956400,           // Friday, Sept 29 2023 - 3AM GMT
  "13.20": 1697022000,	          // Wednesday, Oct 11 2023 - 11AM GMT
  "13.20b": 1697140800,           // Thursday, Oct 12 2023 - 8PM GMT
  "13.21": 1698231600,	          // Wednesday, Oct 25 2023 - 11AM GMT
  "13.22": 1699441200,	          // Wednesday, Nov 8, 2023-11AM GMT

  // Set 10
  "13.23": 1700564400,	          // Tuesday, Nov 21 2023 - 11AM GMT
  "13.24": 1701860400,	          // Wednesday, Dec 6 2023 - 11AM GMT
  "13.24b": 1702497600,	          // Thursday, Dec 14 2023 - 8PM GMT
  "14.1": 1704884400,             // Wednesday, Jan 10 2024 - 11AM GMT
  "14.2": 1706094000,             // Wednesday, Jan 24 2024 - 11AM GMT
  "14.3": 1707303600,             // Wednesday, Feb 7 2024 - 11AM GMT
  "14.3b": 1707854400,            // Tuesday, Feb 13 2024 - 8PM GMT
  "14.4": 1708599600,             // Thursday, Feb 22 2024 - 11AM GMT
  "14.5": 1709722800,             // Wednesday, March 6 2024 - 11AM GMT

  // Set 11
  "14.6": 1710932400,             // Wednesday, March 20 2024 - 11AM GMT
  "14.6b": 1711404000,            // Monday, March 25 2024 - 10PM GMT
  "14.7": 1712142000,             // Wednesday, April 3 2024 - 11AM GMT
  "14.7b": 1712275200,            // Friday, April 5 2024 - 12AM GMT
  "14.8": 1713355200,             // Wednesday, April 17 2024 - 11AM GMT
  "14.8b": 1713484800,            // Friday, April 19 2024 - 12AM GMT
  "14.9": 1714564800,             // Wednesday, May 1 2024 - 11AM GMT
  "14.9b": 1714690800,            // Friday, May 3 2024 - 12AM GMT
  "14.10": 1715774400,            // Wednesday, May 15 2024 - 11AM GMT
  "14.10b": 1715893200,           // Friday, May 17 2024 - 9PM GMT
  "14.11": 1717070400,            // Thursday, May 30 2024 - 11AM GMT
  "14.12": 1718193600,            // Wednesday, June 12 2024 - 11AM GMT
  "14.13": 1719403200,            // Wednesday, June 26 2024 - 11AM GMT
  "14.14": 1721217600,            // Wednesday, July 17 2024 - 11AM GMT

  // Set 12
  "14.15": 1722427200,            // Wednesday, July 31 2024 - 11AM GMT
  "14.16": 1723636800,            // Wednesday, Aug 14 2024 - 11AM GMT
  "14.16b": 1724266800,           // Wednesday August 21 2024 - 7PM GMT
  "14.17": 1724846400,            // Wednesday, Aug 28 2024 - 11AM GMT
  "14.17b": 1724975100,           // Thursday, Aug 29 2024 - 11:45PM GMT
  "14.18": 1726056000,            // Wednesday, Sept 11 2024 - 11AM GMT
  "14.18b": 1726092000,           // Thursday, Aug 29 2024 - 10PM GMT
  "14.19": 1727265600,            // Wednesday, Sept 25 2024 - 11AM GMT
  "14.20": 1728471600,            // Wednesday, Oct 9 2024 - 11AM GMT
  "14.20b": 1728504000,           // Wednesday, Oct 9 2024 - 10PM GMT
  "14.21": 1729681200,            // Wednesday, Oct 23 2024 - 11AM GMT
  "14.22": 1730890800,            // Wednesday, Nov 6 2024 - 11AM GMT

  // Set 13
  "14.23": 1732100400,            // Wednesday, Nov 20 2024 - 11AM GMT
  "14.23b": 1732230000,           // Thursday, Nov 21 2024 - 11PM GMT
  "14.23c": 1732573800,           // Monday, Nov 25 2024 - 10:30PM GMT
  "14.24": 1733914800,            // Wednesday, Dec 11 2024 - 11AM GMT
  "14.24b": 1734472800,           // Wednesday, Dec 18 2024 - 10PM GMT
  "15.1": 1736420400,             // Thursday, Jan 9 2025 - 11AM GMT
  "15.2": 1737630000,             // Thursday, Jan 23 2025 - 11AM GMT
  "15.3": 1738753200,             // Wednesday, Feb 05 2025 - 11AM GMT
  "15.4": 1740049200,             // Thursday, Feb 20 2025 - 11AM GMT
  "15.5": 1741172400,             // Wednesday, Mar 05 2025 - 11AM GMT
  "15.6": 1742382000,             // Wednesday, Mar 19 2025 - 11AM GMT

  // Set 14
  "15.7": 1743591600,             // Wednesday, Apr 02 2025 - 11AM GMT
  "15.8": 1744801200,             // Wednesday, Apr 16 2025 - 11AM GMT
  "15.9": 1746010800,             // Wednesday, Apr 30 2025 - 11AM GMT
  "15.10": 1747220400,            // Wednesday, May 14 2025 - 11AM GMT
  "15.11": 1748430000,            // Wednesday, May 28 2025 - 11AM GMT
  "15.12": 1749639600,            // Wednesday, Jun 11 2025 - 11AM GMT
  "15.13": 1750849200,            // Wednesday, Jun 25 2025 - 11AM GMT
  "15.14": 1752663600,            // Wednesday, Jul 16 2025 - 11AM GMT
  "15.15": 1753873200,            // Wednesday, Jul 30 2025 - 11AM GMT
  "15.16": 1755082800,            // Wednesday, Aug 13 2025 - 11AM GMT
  "15.17": 1756292400,            // Wednesday, Aug 27 2025 - 11AM GMT
  "15.18": 1757502000,            // Wednesday, Sep 10 2025 - 11AM GMT
  "15.19": 1758711600,            // Wednesday, Sep 24 2025 - 11AM GMT
  "15.20": 1759921200,            // Wednesday, Oct 08 2025 - 11AM GMT
  "15.21": 1761130800,            // Wednesday, Oct 22 2025 - 11AM GMT
  "15.22": 1762340400,            // Wednesday, Nov 05 2025 - 11AM GMT
  "15.23": 1763550000,            // Wednesday, Nov 19 2025 - 11AM GMT
  "15.24": 1765364400,            // Wednesday, Dec 10 2025 - 11AM GMT
};

export const PATCH_OFFSET = {
  ["GLOBAL"]: -46800,
  [RiotPlatformRegion.OCEANIA]: -46800,
  [RiotPlatformRegion.JAPAN]: -43200,
  [RiotPlatformRegion.KOREA]: -39600,
  [RiotPlatformRegion.TAIWAN]: -39600,
  [RiotPlatformRegion.SINGAPORE]: -28800,
  [RiotPlatformRegion.VIETNAM]: -28800,
  [RiotPlatformRegion.RUSSIA]: -28800,
  [RiotPlatformRegion.EUROPE_NORTH]: -21600,
  [RiotPlatformRegion.TURKEY]: -18000,
  [RiotPlatformRegion.MIDDLE_EAST]: -18000, // TODO: Confirm this timezone offset.
  [RiotPlatformRegion.EUROPE_WEST]: -10800,
  [RiotPlatformRegion.BRAZIL]: -3600,
  [RiotPlatformRegion.LATIN_AMERICA_NORTH]: 0,
  [RiotPlatformRegion.LATIN_AMERICA_SOUTH]: 7200,
  [RiotPlatformRegion.NORTH_AMERICA]: 10800,
};

interface GetPatchReturn {
  previous: {
    patchId: string;
    timestamp: number;
  },
  current: {
    patchId: string;
    timestamp: number;
  };
  next: {
    patchId: string;
    timestamp: number;
  };
}

export const isBPatch = (patch: string) => {
  return patch !== 'pbe' && /([a-z])+/g.test(patch);
};

export const getPatchTimestamp = ({ patch, region }: { patch: string, region: RiotPlatformRegion }) => {
  const patchTimestamp = PATCH_TIMESTAMPS[patch];
  if (!patchTimestamp) throw new Error(`Attempted to get timestamp for patch ${patch} but failed to find value.`);

  // B patches are deployed to all regions simultaneously.
  if (isBPatch(patch)) return patchTimestamp;

  // All normal patches deploy at different times depending on the region.
  return patchTimestamp + PATCH_OFFSET[region];
};

export function getPatch(region: RiotPlatformRegion = RiotPlatformRegion.OCEANIA): GetPatchReturn {
  const now = Date.now() / 1000;

  let currentPatch: string | null = null;
  let previousPatch: string | null = null;
  let nextPatch: string | null = null;

  for (const patch of Object.keys(PATCH_TIMESTAMPS)) {
    const patchTimestamp = getPatchTimestamp({ patch, region });

    // If no current patch is currently held, start there.
    if (!currentPatch) {
      currentPatch = patch;
      continue;
    }

    // If this patch is before now but after the currently tracked current patch, replace.
    if (patchTimestamp > getPatchTimestamp({ patch: currentPatch, region }) && patchTimestamp < now) {
      previousPatch = currentPatch;
      currentPatch = patch;
      continue;
    }

    // If the patch is after now, but before the currently tracked next patch (or no next patch is being tracked), replace.
    if (patchTimestamp > now && (!nextPatch || patchTimestamp < getPatchTimestamp({ patch: nextPatch, region }))) {
      nextPatch = patch;
    }
  }

  return {
    previous: {
      patchId: previousPatch || '',
      timestamp: previousPatch ? getPatchTimestamp({ patch: previousPatch, region }) : 0,
    },
    current: {
      patchId: currentPatch || '',
      timestamp: currentPatch ? getPatchTimestamp({ patch: currentPatch, region }) : 0,
    },
    next: {
      patchId: nextPatch || '',
      timestamp: nextPatch ? getPatchTimestamp({ patch: nextPatch, region }) : 0,
    }
  };
}

export const getPatchByTimestamp = (timestamp: number, region: RiotPlatformRegion) => {
  let result = null;

  for (const patch of Object.keys(PATCH_TIMESTAMPS)) {
    if (timestamp > getPatchTimestamp({ patch, region })) result = patch;
  }

  if (!result) throw new Error(`Failed to get Patch from timestamp: ${timestamp}`);

  return result;
};

const lastMinors: { [key: number]: string } = {
  9: '24',
  10: '25',
  11: '24',
  12: '23',
  13: '24',
  14: '24',
};

export const isValidTFTPatch = (patch?: string | null) => {
  if (!patch) return false;

  // Remove the hotfix patch letter.
  let result = patch;
  result.match(/([a-z])+/g)?.forEach(match => {
    result = result?.replace(match, '');
  });

  const [majorVersion, minorVersion] = result.split('.').map(i => parseInt(i));

  if (majorVersion && minorVersion) {
    const latestSeason = new Date(Date.now()).getFullYear() - 2010;
    const earliestSeason = 9;

    if (majorVersion <= latestSeason && majorVersion >= earliestSeason) {
      if (minorVersion > 0 && minorVersion <= 25) {
        return true;
      }
    }
  }

  return false;
};

// TODO: Better way to do this?

export const getPreviousPatch = (patch: string) => {
  const [major, minor] = patch.split('.');
  if (!isValidTFTPatch(patch) || !major || !minor) throw new Error(`Cannot determine previous patch - invalid patch: ${patch}`);

  return minor === '1' ? `${parseInt(major) - 1}.${lastMinors[parseInt(major) - 1]}` : `${major}.${parseInt(minor) - 1}`;
};

export const isGreaterThanPatch = (patch1: string, patch2: string) => {
  const [patch1Major, patch1Minor] = patch1.split('.').map(i => parseInt(i));
  const [patch2Major, patch2Minor] = patch2.split('.').map(i => parseInt(i));

  if (!patch1Minor || !patch1Major || !patch2Minor || !patch2Major) throw new Error(`Cannot determine greater patch, invalid patch provided: ${patch1} ${patch2}`);

  return patch1Major === patch2Major ? patch1Minor > patch2Minor : patch1Major > patch2Major;
};

export const getFormattedPatch = (patch: string) => {
  if (!isValidTFTPatch(patch)) return patch;

  // Split the patch into major and minor versions
  const [major, minor] = patch.split('.').map(Number);

  if (!major || !minor) return patch;

  // If the major version is 15 or higher, remap it to the new set (13.X series)
  if (major >= 15) {
    const adjustedMinor = minor + 2; // 15.1 -> 13.3 (adjust by adding 2 to the minor version)
    return `13.${adjustedMinor}`;
  }

  // Otherwise, return the original patch
  return patch;
};