<template>
  <div class="summoner-search">
    <UInput
        v-model="searchTerm"
        :ui="{ base: 'h-full', size: { xl: 'text-xl' },  color: { white: { outline: 'ring-0' }}}"
        padded
        placeholder="Search for Player#Tag..."
        size="xl"
        style="background-color: #252943; height: 3.8rem;"
        @click="isPopoverOpen = true"
        @keydown.enter="submit"
    />

    <ClientOnly>
      <UContextMenu
          v-model="isPopoverOpen"
          :ui="{ container: 'absolute top-20 w-full', background: 'bg-gray-900', ring: 'ring-gray-800', base: customBaseStyle }"
          :virtualElement="{}"
          class="summoner-search__popover"
      >
        <div v-if="searchTerm.trim().length > 1" class="summoner-search__results-wrapper">
          <div class="summoner-search__results-header">
            <strong>Search Results</strong>

            <NuxtLink v-if="!isPopoverLoading" :to="{ name: getPageNames().ROUTE_SEARCH_NAME, params: { searchTerm } }">
              <TeamfightButton height="auto" variant="text">
                See all{{ searchTotal ? ` (${searchTotal})` : '' }}
              </TeamfightButton>
            </NuxtLink>
          </div>

          <div class="summoner-search__results">
            <template v-if="!isPopoverLoading">
              <SummonerSearchResult v-for="summoner in searchResults" :size="size" :summoner="summoner"/>
            </template>

            <div v-if="!isPopoverLoading && searchResults.length === 0" class="summoner-search__no-results">
              There are no Summoners matching your search input.
            </div>

            <TeamfightLoadingIndicator :isLoading="isPopoverLoading"/>
          </div>
        </div>

        <template v-if="favouriteSummoners.length || searchHistory.length">
          <div :class="['summoner-search__history', { '--minimal': size === 'minimal' }]">
            <div class="summoner-search__history-list">
              <div class="summoner-search__history-header">
                <strong>History</strong>

                <TeamfightButton
                    v-if="searchHistory.length"
                    style="font-size: 1.2rem;"
                    variant="text"
                    @click="clearSearchHistory"
                >
                  Clear History
                </TeamfightButton>
              </div>

              <div v-for="summoner in searchHistory.slice(0, 5)" class="summoner-search__list-item-wrapper">
                <NuxtLink
                    :to="getSummonerPageLink(summoner)"
                    class="summoner-search__list-item"
                    @click="isPopoverOpen = false"
                >
                  <SummonerProfileIcon :summoner="summoner" disableLink hideLevel/>

                  {{ summoner.gameName }}<span>#{{ summoner.tagLine }}</span>
                </NuxtLink>

                <TeamfightIcon
                    class="summoner-search__list-item-close"
                    color="white"
                    name="x"
                    style="font-size: 1.4rem;"
                    @click="e => removeHistorySummoner(e, summoner)"
                />
              </div>
            </div>

            <div class="summoner-search__history-list">
              <div class="summoner-search__history-header">
                <strong>Favourites</strong>
              </div>
              <div v-for="summoner in favouriteSummoners.slice(0, 5)" class="summoner-search__list-item-wrapper">
                <NuxtLink
                    :to="getSummonerPageLink(summoner)"
                    class="summoner-search__list-item"
                    @click="isPopoverOpen = false"
                >
                  <SummonerProfileIcon :summoner="summoner" disableLink hideLevel/>
                  {{ summoner.gameName }}<span>#{{ summoner.tagLine }}</span>
                </NuxtLink>

                <TeamfightIcon
                    class="summoner-search__list-item-close"
                    color="white"
                    name="x"
                    style="font-size: 1.4rem;"
                    @click="e => removeFavouriteSummoner(e, summoner)"
                />
              </div>
            </div>
          </div>
        </template>
      </UContextMenu>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { findSummoners } from '~/graphql/query/findSummoners/findSummoners';
import { useFavouriteSummoners, useSearchHistory } from "~/helpers/storage";
import { Summoner } from "~/types/summoner";

interface Props {
  size?: 'full' | 'minimal';
  customBaseStyle?: string;
}

withDefaults(defineProps<Props>(), {
  size: "full",
});

const searchHistory = useSearchHistory();
const favouriteSummoners = useFavouriteSummoners();

const searchTerm = ref('');

const searchTotal = ref(0);
const searchResults = ref<any[]>([]);

const isPopoverOpen = ref(false);
const isPopoverLoading = ref(false);

const removeFavouriteSummoner = (e: MouseEvent, summoner: Summoner) => {
  e.stopPropagation();
  favouriteSummoners.value = favouriteSummoners.value.filter(s => s.uuid !== summoner.uuid);
};

const removeHistorySummoner = (e: MouseEvent, summoner: Summoner) => {
  e.stopPropagation();
  searchHistory.value = searchHistory.value.filter(s => s.uuid !== summoner.uuid);
};

const clearSearchHistory = () => {
  searchHistory.value = [];
};

const submit = () => {
  if (!searchTerm.value.trim()) return;

  if (searchTerm.value.includes('#')) {
    navigateTo({
      name: getPageNames().ROUTE_SUMMONER_NAME,
      params: { region: 'na', summonerName: searchTerm.value.replace('#', '-').trim() }
    });
  } else {
    navigateTo({
      name: getPageNames().ROUTE_SEARCH_NAME,
      params: { searchTerm: searchTerm.value.trim() }
    });
  }

  searchTerm.value = '';
  searchTotal.value = 0;
  searchResults.value = [];
};

const lastInputTimestamp = ref<number | null>(null);

watch([searchTerm], async () => {
  const term = searchTerm.value.replace(/[^\w\s]/g, '\\$&');

  if (term && !!term.trim()) {
    isPopoverLoading.value = true;

    const timestamp = Date.now();
    lastInputTimestamp.value = timestamp;

    // We check against the timestamp to only get the latest result, and to give a buffer period so we're not searching on every key press.
    setTimeout(async () => {
      if (lastInputTimestamp.value === timestamp) {
        const { data } = await findSummoners({ searchTerm: term }, { resolve: true });

        if (data.value && lastInputTimestamp.value === timestamp) {
          searchResults.value = data.value.results;
          searchTotal.value = data.value.total;
        }

        isPopoverLoading.value = false;
      }
    }, 400);
  } else isPopoverLoading.value = false;
});
</script>

<style lang="scss" scoped>
.summoner-search {
  display: grid;
  position: relative;
  background-color: var(--UI-Secondary);
  border-radius: var(--BorderRadius-S);
  border: 0.1rem solid var(--UI-Border);
  height: 4rem;
}

.summoner-search__results-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .summoner-search__results-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
  }

  .summoner-search__results {
    position: relative;
    min-height: 10rem;
    display: flex;
    flex-direction: column;

    .summoner-search__no-results {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      opacity: 0.6;
    }

    .summoner-search__see-more {
      display: flex;
      align-items: center;
      transition: all 0.25s ease;
      border-radius: 0.3rem;

      &:hover {
        background-color: var(--UI-Accent-60);
      }
    }
  }
}

.summoner-search__history {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;

  @include breakpoint('sm') {
    grid-template-columns: 1fr 1fr;
  }

  &.--minimal {
    grid-template-columns: 1fr;
  }

  .summoner-search__history-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
  }

  .summoner-search__history-list {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .summoner-search__list-item-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .summoner-search__list-item {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        transition: color 0.25s ease;
        cursor: pointer;

        span {
          font-size: 1rem;
          opacity: 0.6;
        }

        &:hover {
          color: var(--Color-Orange);
        }
      }

      .summoner-search__list-item-close {
        transition: color 0.25s ease;
        cursor: pointer;

        &:hover {
          color: var(--Color-Orange);
        }
      }
    }
  }
}
</style>