<template>
  <div id="app" ref="appRef" :class="{ 'overflow-hidden': generalStore.isMobileNavigationOpen }">
    <template v-if="!isMaintenance || overrideMaintenance">
      <TeamfightNotificationsHandler v-if="!isScreenshot"/>

      <NuxtLayout>
        <NuxtPage/>
        <UModals/>
      </NuxtLayout>
    </template>

    <template v-else>
      <ViewWrapper :backdropImage="getCDNImageUrl('/TFTSet12_Wallpaper.jpg')" height="100%" hideAd>
        <div class="maintenance">
          <MaintenanceNotice/>
          <AdminOnly>
            <TeamfightButton @click="overrideMaintenance = true;">
              See Website Anyway
            </TeamfightButton>
          </AdminOnly>
        </div>
      </ViewWrapper>
    </template>
  </div>

  <ContextMenu/>
</template>

<script lang="ts" setup>
import { useNotification } from "@kyvg/vue3-notification";
import { Cookie, useApolloToken } from "~/utils/cookie";
import { Ref } from "@vue/reactivity";
import { useGeneralStore } from "~/stores/GeneralStore";
import { TitleTemplate } from "@unhead/vue";
import { getMe } from "~/graphql/query/getMe/getMe";
import { useUserStore } from "~/stores/UserStore";
import { ROUTE_TEAM_BUILDER_QUERY } from "~/types/router";

useHead({
  script: []
});

useSeoMeta({
  titleTemplate: ((title?: string) => `${title ? `${title} | ` : ''}Teamfight.lol`) as TitleTemplate,
  ogTitle: 'Teamfight.lol',
  ogSiteName: 'Teamfight.lol',
  applicationName: 'Teamfight.lol',
  description: 'The best TFT team compositions, champion stats, and strategies by Teamfight.lol - Learn the best items, units, and meta. Find comprehensive guides, tier lists, and tools to elevate your Teamfight Tactics gameplay and join our community!',
  ogDescription: 'The best TFT team compositions, champion stats, and strategies by Teamfight.lol - Learn the best items, units, and meta. Find comprehensive guides, tier lists, and tools to elevate your Teamfight Tactics gameplay and join our community!'
});

const route = useRoute();

const query = useRouteQuery<ROUTE_TEAM_BUILDER_QUERY>();
const isScreenshot = computed(() => query.value.mode && query.value.mode === 'screenshot');

const config = useRuntimeConfig();
const isMaintenance = computed(() => config.public.maintenance_mode);
const overrideMaintenance = ref(false);

const appRef: Ref<HTMLDivElement | null> = ref(null);

const generalStore = useGeneralStore();

generalStore.startTimeInterval();

const { notify } = useNotification();

const { md: isTabletOrGreater } = useScreen();

onMounted(async () => {
  const userStore = useUserStore();

  if (!userStore.user && import.meta.client) {
    const authToken = useApolloToken();

    if (authToken.value) {
      const { data } = await getMe({ resolve: true });

      if (data.value) {
        userStore.user = data.value;
        userStore.adminMode = true;
      }
    }
  }

  if (!userStore.adminMode && !isScreenshot.value) {
    generalStore.enableAds = true;
  }

  const discordNotificationCookie = useCookie(Cookie.DISCORD_NOTIFICATION, { maxAge: 1704085200 });
  if (!discordNotificationCookie.value && isTabletOrGreater.value) {
    notify({
      title: 'Join our Discord community!',
      text: `Teamfight.lol has a Discord server!<br/><br/>Join us by clicking the button below to suggest new features, report bugs, chat with other members of the community, and more!`,
      type: "discord",
      duration: -1,
      data: {
        icon: 'discord',
        link: 'https://discord.gg/3gDXwZ8pG9',
        CTA: 'Join Us',
        onClose: (close: () => void) => {
          discordNotificationCookie.value = 'true';
          close();
        }
      }
    });
  }
});

watch(() => route.fullPath, () => {
  if (import.meta.client) {
    // @ts-ignore
    appRef.value?.scrollTo({ top: 0, behavior: "smooth" });
  }
});

onErrorCaptured((err, _instance, _info) => {
  notify({
    title: err.name,
    text: err.message,
    group: 'error',
    type: "error",
    duration: process.env.NUXT_PUBLIC_NODE_ENV !== 'production' ? -1 : 3000
  });

  console.error(err);

  // Returning false stops the error from propagating further.
  return false;
});
</script>

<style lang="scss">
#app {
  height: 100dvh;

  //noinspection CssInvalidPropertyValue
  overflow-y: auto;

  &.overflow-hidden {
    overflow: hidden;
  }
}

.maintenance {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  color: white;
}
</style>
