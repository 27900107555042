<template>
  <div class="mobile-nav-item">
    <button
        :class="['mobile-nav-item__content', { '--active': isRouteActive }]"
        @click.stop.prevent="handleToggle"
    >
      <div class="mobile-nav-item__label">
        <div class="flex items-center">
          <span>
            {{ name }}
          </span>
          <div v-if="beta" class="mobile-nav-item__beta">BETA</div>
        </div>

        <TeamfightIcon
            v-if="isDropdownMenu"
            :class="{ 'transform rotate-90': isOpen }"
            class="mobile-nav-item__collapse-icon transition-transform"
            name="chevronRight"
        />
      </div>
    </button>

    <div v-if="isDropdownMenu && isOpen" class="mobile-nav-item__list">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { RouteLocationAsRelativeGeneric, RouteLocationRaw } from '#vue-router';
import { useRouteMatch } from "~/composables/useRouteMatch";
import { IconName } from "~/components/Teamfight/Icon/IconRegistry";

interface Props {
  name: string;
  blurb?: string;
  to: RouteLocationRaw;
  icon?: IconName;
  imageUrl?: string;
  imageSize?: string;
  matchPath?: string;
  isDropdownMenu?: boolean;
  isDropdownItem?: boolean;
  beta?: boolean;
}

const props = defineProps<Props>();

const isRouteActive = useRouteMatch({
  matchPath: props.matchPath,
  routeName: (props.to as RouteLocationAsRelativeGeneric).name,
});

const isOpen = ref(false);

const handleToggle = () => {
  if (!props.isDropdownMenu) {
    return navigateTo(props.to);
  }
  isOpen.value = !isOpen.value;
};
</script>

<style lang="scss" scoped>
.mobile-nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.25s ease;
  fill: white;

  .mobile-nav-item__content {
    padding: 1.2rem 0;

    &:hover {
      color: var(--Color-Orange);
      fill: var(--Color-Orange);
      background-color: rgba(241, 93, 59, 0.1);
    }
  }

  .mobile-nav-item__label {
    font-weight: var(--FontWeight-Medium);
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: normal;
    font-size: 1.8rem;
    padding: 0 5%;

    .mobile-nav-item__beta {
      transition: border 0.25s ease;
      display: flex;
      align-items: center;
      font-size: 1rem;
      border: none;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.3rem;
      padding: 0.4rem 0.6rem;
      margin-left: 0.6rem;
      opacity: 0.6;
    }

    .mobile-nav-item__collapse-icon {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
      margin-top: -0.3rem;
      align-self: flex-end;
    }
  }

  .mobile-nav-item__blurb {
    font-weight: var(--FontWeight-Medium);
    opacity: 0.8;
    margin-top: 0.2rem;
    font-size: 1.2rem;
  }

  .mobile-nav-item__list {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .--active {
    > .mobile-nav-item__label {
      opacity: 1;
      font-weight: var(--FontWeight-Bold);
      color: var(--Color-Yellow);
      filter: grayscale(0);
      fill: url(#tf-gradient);
      background: linear-gradient(243.96deg, #f1bd00 -25.57%, #f15d3b 160.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .mobile-nav-item__beta {
        background: rgba(241, 93, 59, 0.2);
        color: var(--Color-Orange);
        border: none;
        opacity: 1;
      }
    }
  }
}
</style>
