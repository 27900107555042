import { useRequestEvent, useRuntimeConfig, useState } from "#imports";
import { defineNuxtPlugin } from "nuxt/app";
export default defineNuxtPlugin({
  name: "nuxt-site-config:init",
  enforce: "pre",
  async setup(nuxtApp) {
    const state = useState("site-config");
    if (import.meta.server) {
      const context = useRequestEvent()?.context;
      nuxtApp.hooks.hook("app:rendered", () => {
        state.value = context?.siteConfig.get({
          debug: useRuntimeConfig()["nuxt-site-config"].debug,
          resolveRefs: true
        });
      });
    }
    let stack = {};
    if (import.meta.client)
      stack = state.value || window.__NUXT_SITE_CONFIG__;
    return {
      provide: {
        nuxtSiteConfig: stack
      }
    };
  }
});
