<template>
  <TeamfightToggle
      v-model="userStore.adminMode"
      :labelLink="`${getAdminUrl()}/home`"
      label="Admin Mode"
      @toggle="toggleAdminMode"
  />
</template>

<script lang="ts" setup>
import { getAdminUrl } from "~/helpers/getAdminUrl";
import { useUserStore } from "~/stores/UserStore";
import { useGeneralStore } from "~/stores/GeneralStore";

const userStore = useUserStore();
const generalStore = useGeneralStore();

const toggleAdminMode = (i: boolean) => {
  userStore.adminMode = i;
  generalStore.enableAds = !userStore.adminMode;
};
</script>