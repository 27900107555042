<template>
  <div>
    <TheHeader/>

    <main class="page-wrapper">
      <slot/>
    </main>

    <TheFooter/>
  </div>
</template>

<script lang="ts" setup>
import TheFooter from "~/components/TheFooter.vue";
import TheHeader from "~/components/TheHeader.vue";

onMounted(() => {
  // Ad Block Recovery Inline Script
  const inlineScript = document.createElement("script");
  inlineScript.type = "text/javascript";
  inlineScript.innerHTML = `
    !function() {
      "use strict";
      var e;
      e = document;
      (function() {
        var t, n;
        function r() {
          var t = e.createElement("script");
          t.src = "https://cafemedia-com.videoplayerhub.com/galleryplayer.js";
          e.head.appendChild(t);
        }
        function a() {
          var t = e.cookie.match("(^|[^;]+)\\s*__adblocker\\s*=\\s*([^;]+)");
          return t && t.pop();
        }
        function c() {
          clearInterval(n);
        }
        return {
          init: function() {
            var e;
            "true" === (t = a()) ? r() : (e = 0, n = setInterval(function() {
              100 !== e && "false" !== t || c();
              "true" === t && (r(), c());
              t = a();
              e++;
            }, 50));
          },
        };
      })().init();
    }();
  `;

  document.body.appendChild(inlineScript);
});
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100dvh - var(--Nav-Height) - var(--Footer-Height));
}
</style>