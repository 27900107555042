<template>
  <div v-if="options?.length" ref="currentElement" :class="[{ '--sub-menu': isSubMenu }]" :style="customStyle">
    <div v-for="option in options" class="context-menu__option" @click="handleClick(option)">
      <div class="context-menu__option-icon">
        <TeamfightIcon v-if="option.icon" :name="option.icon"/>

        <div v-else-if="option.image" class="context-menu__option-image">
          <TeamfightImage :alt="option.label" :src="option.image" :style="{ height: 'auto', width: '100%' }"/>
        </div>
      </div>

      <div class="context-menu__option-label">
        {{ option.label }}
      </div>

      <div v-if="option.subOptions?.length">
        <TeamfightIcon name="chevronRight"/>
      </div>

      <ContextMenuOptions
          v-if="option.subOptions?.length"
          :isSubMenu="true"
          :options="option.subOptions"
          @click="handleClick"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ContextMenuOption } from "~/stores/ContextMenuStore";

interface Props {
  options?: ContextMenuOption[];
  isSubMenu?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isSubMenu: false,
});

const emit = defineEmits(["click"]);

const currentElement = useTemplateRef("currentElement");
const parentElement = useParentElement();

const { left, right } = useElementBounding(parentElement);

const currentElementWidth = computed(() => currentElement.value?.getBoundingClientRect()?.width ?? 0);

const customStyle = computed(() => {
  if (!props.isSubMenu) return {};

  const viewportWidth = window.innerWidth;
  const parentElementLeft = left.value;
  const parentElementRight = right.value;

  const spaceOnRight = viewportWidth - parentElementRight;
  const spaceOnLeft = parentElementLeft;

  if (spaceOnRight >= currentElementWidth.value) {
    // right
    return {
      top: 0,
      left: "100%",
    };
  } else if (spaceOnLeft >= currentElementWidth.value) {
    // left
    return {
      top: 0,
      right: "100%",
      left: "unset",
    };
  }

  return {
    top: 0,
    left: `calc(100% - ${currentElementWidth.value - spaceOnRight}px)`,
  };
});

const handleClick = (option: ContextMenuOption) => {
  emit("click", option);
};
</script>

<style lang="scss" scoped>
.context-menu__option {
  position: relative;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  transition: 0.25s ease all;
  cursor: pointer;
  fill: white;
  font-size: 1.3rem;
  font-weight: var(--FontWeight-Medium);
  min-width: 15rem;

  &:hover {
    background-color: var(--UI-Border);
    fill: var(--UI-Primary);

    &.context-menu__option-label {
      color: var(--UI-Primary);
    }
  }

  .context-menu__option-icon {
    fill: white;

    .context-menu__option-image {
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  .context-menu__option-label {
    flex: 1;
  }

  &:hover > * {
    opacity: 1;
    visibility: visible;
  }
}

.--sub-menu {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--UI-Secondary);
  border: 0.1rem solid var(--UI-Border);
  border-radius: 0.3rem;
  z-index: 100;
}
</style>
