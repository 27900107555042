<template>
  <div ref="element" class="nav-link">
    <NuxtLink
        :class="{ '--dropdown-item': isDropdownItem, 'router-link-active': isRouteActive }"
        :to="to"
    >
      <div v-if="icon || imageUrl" class="nav-link__icon">
        <TeamfightIcon v-if="icon" :name="icon" size="1.6rem"/>
        <TeamfightImage
            v-else
            :src="imageUrl || getFallbackIconUrl()"
            :style="{ height: imageSize || '3.6rem', width: imageSize || '3.6rem', borderRadius: '0.3rem' }"
        />
      </div>

      <div class="nav-link__label">
        <span>
          {{ name }}
        </span>

        <TeamfightTag v-if="beta" :isInactive="!isHovered && !isRouteActive">
          BETA
        </TeamfightTag>

        <TeamfightIcon v-if="isDropdownMenu" margin="-0.2rem 0 0 0" name="chevronDown" size="2rem"/>
      </div>

      <div v-if="blurb" class="nav-link__blurb">
        {{ blurb }}
      </div>
    </NuxtLink>

    <div v-if="isDropdownMenu" class="nav-link-menu">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { RouteLocationRaw } from "#vue-router";
import { useRouteMatch } from "~/composables/useRouteMatch";
import { RouteLocationAsRelativeGeneric } from "vue-router";
import { IconName } from "~/components/Teamfight/Icon/IconRegistry";

interface Props {
  name: string;
  blurb?: string;
  to: RouteLocationRaw;
  icon?: IconName;
  imageUrl?: string;
  imageSize?: string;
  matchPath?: string;
  isDropdownMenu?: boolean;
  isDropdownItem?: boolean;
  beta?: boolean;
}

const props = defineProps<Props>();

const element = ref();
const isHovered = useElementHover(element);

const isRouteActive = useRouteMatch({
  matchPath: props.matchPath,
  routeName: (props.to as RouteLocationAsRelativeGeneric).name,
});
</script>

<style lang="scss" scoped>
.nav-link {
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  fill: white;
  height: 100%;

  .nav-link__label {
    font-weight: var(--FontWeight-Medium);
    display: flex;
    gap: 0.2rem;
    align-items: center;
    line-height: normal;

    span {
      margin-top: 0.2rem;
      margin-left: 0.8rem;
    }
  }

  .nav-link__blurb {
    font-weight: var(--FontWeight-Medium);
    opacity: 0.8;
    margin-top: 0.2rem;
    margin-left: 0.8rem;
    font-size: 1.2rem;
  }

  .nav-link-menu {
    padding: 1rem;
    position: absolute;
    top: 90%;
    left: 0;
    display: none;
    flex-direction: column;
    background-color: var(--UI-Secondary);
    border-radius: 0 0 var(--BorderRadius-S) var(--BorderRadius-S);
    box-shadow: 0.2rem 0.6rem 0.8rem rgba(0, 0, 0, 0.4);
    z-index: 1000;
    border: 0.1rem solid #474c6d;
    gap: 1rem;

    .nav-link {
      &:hover {
        .nav-link__label {
          opacity: 1;
        }
      }

      &.router-link-active {
        .nav-link__label {
          opacity: 1;
          filter: none;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;

    .nav-link-menu {
      display: flex;
    }
  }

  .router-link-active {
    > .nav-link__label {
      opacity: 1;
      font-weight: var(--FontWeight-Bold);
      color: var(--Color-Yellow);
      filter: grayscale(0);
      fill: url(#tf-gradient);
      background: linear-gradient(243.96deg, #F1BD00 -25.57%, #F15D3B 160.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .--dropdown-item {
    display: grid;
    padding: 1rem;
    grid-template-columns: 3rem 1fr;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    gap: 0 2rem;
    width: 40rem;
    transition: all 0.25s ease;

    .nav-link__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--BorderRadius-S);
      grid-row: span 2;
      border: 0.1rem solid var(--UI-Text);
      height: 3.8rem;
      width: 3.8rem;
      background-color: #1D1D1D;
    }

    &:hover {
      background-color: rgba(241, 93, 59, 0.1);
      color: var(--Color-Orange);
      fill: var(--Color-Orange);
      border-radius: var(--BorderRadius-S);

      .nav-link__icon {
        border-color: var(--Color-Orange);

        img {
          border-color: var(--Color-Orange);
        }
      }

      .nav-link__beta {
        background: rgba(241, 93, 59, 0.2);
        color: var(--Color-Orange);
        border: none;
        opacity: 1;
      }
    }
  }
}
</style>