<template>
  <Teleport to="body">
    <UContextMenu
      v-model="isOpen"
      :virtual-element="virtualElement"
      :ui="{ base: 'overflow-visible' }"
      :popper="{ offset: 0 }"
    >
      <div ref="target" class="context-menu">
        <div v-for="optionGroup in options" class="context-menu__option-group">
          <ContextMenuOptions :options="optionGroup" @click="clickOption" />
        </div>
      </div>
    </UContextMenu>
  </Teleport>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { ContextMenuOption, useContextMenu } from "~/stores/ContextMenuStore";

const store = useContextMenu();
const { positionX, positionY, options } = storeToRefs(store);

const target = ref<HTMLDivElement | null>(null);
const virtualElement = ref({ getBoundingClientRect: () => ({}) });
const isOpen = ref(false);

const clickOption = async (option: ContextMenuOption) => {
  if (option.action) {
    option.action();
  }

  store.closeContextMenu();
};

onClickOutside(target, () => store.closeContextMenu());

watch(
  () => ({
    options: options.value,
    left: positionX.value,
    top: positionY.value,
  }),
  ({ options, left, top }) => {
    if (options.length && left && top) {
      virtualElement.value.getBoundingClientRect = () => ({
        width: 0,
        height: 0,
        top,
        left,
      });
      isOpen.value = true;
    }
  }
);
watch(isOpen, (opened) => !opened && store.closeContextMenu());
</script>

<style lang="scss" scoped>
.context-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--UI-Secondary);
  border: 0.1rem solid var(--UI-Border);
  border-radius: 0.3rem;
  z-index: 1000;

  .context-menu__option-group {
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid var(--UI-Border);

    &:first-child {
      border-top: none;
    }
  }
}
</style>
