import { defineStore } from "pinia";
import { format } from "timeago.js";
import { closeMobileNavigation } from "./actions/closeMobileNavigation";
import { openMobileNavigation } from "./actions/openMobileNavigation";
import { toggleMobileNavigation } from "./actions/toggleMobileNavigation";

interface State {
  currentTime: number;
  isMobileNavigationOpen: boolean;
  enableAds: boolean;
}

export const useGeneralStore = defineStore("general", {
  state: (): State => ({
    currentTime: Date.now(),
    isMobileNavigationOpen: false,
    enableAds: false
  }),

  getters: {
    getTimeAgo: (state) => {
      return (date: Date | number) => format(date, undefined, { relativeDate: state.currentTime });
    }
  },

  actions: {
    startTimeInterval() {
      // Important that this only runs on the client, otherwise will infinitely freeze a deployment.
      if (import.meta.client) {
        setInterval(() => {
          this.currentTime = Date.now();
        }, 1000);
      }
    },
    closeMobileNavigation,
    openMobileNavigation,
    toggleMobileNavigation,
  },
});
