<template>
  <footer :style="{ padding }">
    <p>
      © {{ websiteDomain }}. All Rights Reserved. tft@{{ websiteDomain }} <br/>
      {{ websiteDomain }} isn’t endorsed by Riot Games and doesn't reflect the
      views or opinions of Riot Games or anyone officially involved in producing
      or managing League of Legends. League of Legends and Riot Games are
      trademarks or registered trademarks of Riot Games, Inc. League of Legends
      © Riot Games, Inc.
    </p>

    <div class="footer-links">
      <NuxtLink :to="{ name: getPageNames().ROUTE_PRIVACY_POLICY_NAME }">
        Privacy Policy
      </NuxtLink>

      <AdminOnly>
        <TeamfightToggle
            v-model="generalStore.enableAds"
            label="Enable Ads"
            @toggle="generalStore.enableAds = !generalStore.enableAds"
        />
      </AdminOnly>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useGeneralStore } from "~/stores/GeneralStore";

const websiteDomain = "teamfight.lol";

const generalStore = useGeneralStore();

const padding = computed(() => generalStore.enableAds ? '1.6rem 5% calc(9.2rem + 1.6rem) 5%' : '1.6rem 5%');
</script>

<style lang="scss" scoped>
footer {
  min-height: var(--Footer-Height);
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  font-size: 12px;
  background-color: var(--UI-Secondary);

  p {
    max-width: 124rem;
    margin: 0 auto;
  }

  .footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    a {
      transition: all 0.25s ease;

      &:hover {
        color: var(--UI-Accent);
      }
    }
  }
}
</style>