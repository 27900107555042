import { getConventionalRegionName } from "~/helpers/getConventionalRegionName";
import { RiotPlatformRegion } from "~/types/riot";

export const parseRegion = (regionString: string) => {
  // Philippines & Thailand were merged into SG.
  if (['ph', 'th'].includes(regionString)) {
    return RiotPlatformRegion.SINGAPORE;
  }

  for (const region of Object.values(RiotPlatformRegion)) {
    if (getConventionalRegionName(region).toLowerCase() === regionString.toLowerCase() || region.toLowerCase() === regionString.toLowerCase()) {
      return region as RiotPlatformRegion;
    }
  }

  throw new Error(`Unable to parse region: ${regionString}`);
};