import { useGeneralStore } from "~/stores/GeneralStore";

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server) return;

  // Function to add the Raptive and Ad Block Recovery scripts
  const addScripts = () => {
    // Add Raptive Script
    const raptiveScript = document.createElement('script');
    raptiveScript.type = 'text/javascript';
    raptiveScript.dataset.noOptimize = '1';
    raptiveScript.dataset.cfasync = 'false';
    raptiveScript.innerHTML = `
      (function(w, d) {
        w.adthrive = w.adthrive || {};
        w.adthrive.cmd = w.adthrive.cmd || [];
        w.adthrive.plugin = 'adthrive-ads-manual';
        w.adthrive.host = 'ads.adthrive.com';
        var s = d.createElement('script');
        s.async = true;
        s.referrerpolicy = 'no-referrer-when-downgrade';
        s.src = 'https://' + w.adthrive.host + '/sites/67463d3118c4b946b685441f/ads.min.js?referrer=' + w.encodeURIComponent(w.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1);
        var n = d.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(s, n);
      })(window, document);
    `;

    raptiveScript.id = 'raptive-script';

    if (!document.head.querySelector('#raptive-script')) {
      document.head.appendChild(raptiveScript);
    }

    // Add Ad Block Recovery Script
    const adBlockRecoveryScript = document.createElement('script');
    adBlockRecoveryScript.type = 'text/javascript';
    adBlockRecoveryScript.src = 'https://btloader.com/tag?o=5698917485248512&upapi=true&domain=teamfight.lol';
    adBlockRecoveryScript.async = true;
    adBlockRecoveryScript.id = 'ad-block-recovery-script';

    if (!document.body.querySelector('#ad-block-recovery-script')) {
      document.body.appendChild(adBlockRecoveryScript);
    }
  };

  // Function to remove scripts
  const removeScripts = () => {
    const raptiveScript = document.head.querySelector('#raptive-script');
    if (raptiveScript) raptiveScript.remove();

    const adBlockRecoveryScript = document.body.querySelector('#ad-block-recovery-script');
    if (adBlockRecoveryScript) adBlockRecoveryScript.remove();
  };

  // Function to remove all `.adthrive-ad` elements
  const removeAdElements = () => {
    const adElements = document.querySelectorAll('.adthrive-ad');
    adElements.forEach((el) => el.remove());
  };

  const generalStore = useGeneralStore();

  // Watch the store state for changes
  watch(() => generalStore.enableAds, (isAdsEnabled) => {
    if (isAdsEnabled) addScripts();
    else {
      removeScripts();
      removeAdElements();
    }
  }, { immediate: true });
});