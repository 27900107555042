<template>
  <header>
    <div class="top-header">
      <div class="inner-wrapper">
        <TheMobileNav/>

        <div class="logo-wrapper">
          <TeamfightLogo :size="3"/>

          <TeamfightTag v-if="userStore.isAdmin && md">
            ADMIN
          </TeamfightTag>

          <TeamfightTag v-else-if="userStore.isVIP && md">
            VIP
          </TeamfightTag>
        </div>

        <div class="search-wrapper">
          <SummonerSearch :size="lg ? 'full' : 'minimal'"/>
        </div>

        <div class="top-header__actions">
          <LocalOnly v-if="md">
            <ProductionAPIToggle/>
          </LocalOnly>

          <AdminOnly v-if="md" alwaysShow subtle>
            <AdminModeToggle/>
          </AdminOnly>

          <NuxtLink class="discord" external href="https://discord.gg/3gDXwZ8pG9" target="_blank">
            <TeamfightIcon color="white" name="discord" size="3.5rem"/>
          </NuxtLink>
        </div>
      </div>
    </div>

    <TheNavbar/>
  </header>
</template>

<script lang="ts" setup>
import { useUserStore } from '~/stores/UserStore';
import ProductionAPIToggle from "~/components/ProductionAPIToggle.vue";
import AdminModeToggle from "~/components/AdminModeToggle.vue";

const userStore = useUserStore();
const { md, lg } = useScreen();
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 99;

  @include breakpoint('md') {
    position: unset;
  }

  .top-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--TopNav-Height);
    background-color: var(--UI-Primary);
    padding: 0 5%;

    .inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      max-width: var(--View-MaxWidth);
      margin: 0 auto;

      @include breakpoint('xl') {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .logo-wrapper {
        display: flex;
        align-content: center;
        gap: 1rem;
      }

      .search-wrapper {
        display: none;
        flex: 1;
        max-width: 50rem;
        margin: 0 5%;

        @include breakpoint('md') {
          display: unset;
        }

        @include breakpoint('xl') {
          display: grid;
          margin: 0;
        }
      }

      .top-header__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        @include breakpoint('md') {
          gap: 2rem;
        }
      }
    }
  }
}
</style>
