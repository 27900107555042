import { Summoner } from "~/types/summoner";
import { getConventionalRegionName } from "~/helpers/getConventionalRegionName";
import { TFTSet } from "~/data/sets";
import { RouteLocationNamedRaw } from "vue-router";
import {
  ROUTE_DATABASE_AUGMENT_DETAILS_PARAMS,
  ROUTE_DATABASE_CHAMPION_DETAILS_PARAMS,
  ROUTE_DATABASE_ITEM_DETAILS_PARAMS,
  ROUTE_SUMMONER_PARAMS
} from "~/types/router";
import { parseRegion } from "~/helpers/parseRegion";

export const getPageNames = () => {
  return {
    ROUTE_HOME_NAME: "Home",

    ROUTE_DATABASE_AUGMENTS_NAME: "Database-Augments",
    ROUTE_DATABASE_AUGMENT_DETAILS_NAME: "Database-Augments-AugmentDetails",
    ROUTE_DATABASE_CHAMPIONS_NAME: "Database-Champions",
    ROUTE_DATABASE_CHAMPION_DETAILS_NAME: "Database-Champions-ChampionDetails",
    ROUTE_DATABASE_ITEMS_NAME: "Database-Items",
    ROUTE_DATABASE_ITEM_DETAILS_NAME: "Database-Items-ItemDetails",
    ROUTE_DATABASE_TRAITS_NAME: "Database-Traits",
    ROUTE_DATABASE_TRAIT_DETAILS_NAME: "Database-Trait-TraitDetails",
    ROUTE_DATABASE_CHARMS_NAME: "Database-Charms",

    ROUTE_STATS_EXPLORER_NAME: "Stats-Explorer",
    ROUTE_STATS_AUGMENTS_NAME: "Stats-Augments",
    ROUTE_STATS_COMPS_NAME: "Stats-Comps",
    ROUTE_STATS_ITEMS_NAME: "Stats-Items",
    ROUTE_STATS_TRAITS_NAME: "Stats-Traits",
    ROUTE_STATS_CHAMPIONS_NAME: "Stats-Champions",

    ROUTE_SUMMONER_NAME: "Summoner",

    ROUTE_LEADERBOARD_NAME: "Leaderboard",
    ROUTE_LEADERBOARD_TOP_NAME: "Leaderboard-Top",

    ROUTE_TEAM_BUILDER_NAME: "Team-Builder",

    ROUTE_SEARCH_NAME: "Search",

    ROUTE_PRIVACY_POLICY_NAME: "Privacy"
  };
};

export const getChampionPageLink = (args: { set: TFTSet, championName: string }): RouteLocationNamedRaw => {
  const urlName = args.championName
      .replaceAll(" ", "-")
      .replaceAll("'", "")
      .toLowerCase();

  return {
    name: getPageNames().ROUTE_DATABASE_CHAMPION_DETAILS_NAME,
    params: { setUrlName: args.set.setUrlName, championName: urlName } as ROUTE_DATABASE_CHAMPION_DETAILS_PARAMS
  };
};

export const getItemPageLink = (args: { set: TFTSet, itemName: string }): RouteLocationNamedRaw => {
  const urlName = args.itemName
      .replaceAll(" ", "-")
      .replaceAll("'", "")
      .toLowerCase();

  return {
    name: getPageNames().ROUTE_DATABASE_ITEM_DETAILS_NAME,
    params: { setUrlName: args.set.setUrlName, itemName: urlName } as ROUTE_DATABASE_ITEM_DETAILS_PARAMS
  };
};

export const getAugmentPageLink = (args: { set: TFTSet, augmentName: string }): RouteLocationNamedRaw => {
  const urlName = args.augmentName
      .replaceAll(" ", "-")
      .replaceAll("'", "")
      .toLowerCase();

  return {
    name: getPageNames().ROUTE_DATABASE_AUGMENT_DETAILS_NAME,
    params: { setUrlName: args.set.setUrlName, augmentName: urlName } as ROUTE_DATABASE_AUGMENT_DETAILS_PARAMS
  };
};

export const getSummonerPageLink = (summoner: Summoner): RouteLocationNamedRaw | undefined => {
  const region = getConventionalRegionName(parseRegion(summoner.region));

  if (!summoner.gameName || !summoner.tagLine) return undefined;

  const summonerName = `${summoner.gameName}-${summoner.tagLine}`;

  return {
    name: getPageNames().ROUTE_SUMMONER_NAME,
    params: { region, summonerName } as ROUTE_SUMMONER_PARAMS
  };
};