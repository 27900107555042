<template>
  <TeamfightLogo :size="6"/>

  <h1>Teamfight.lol is currently undergoing scheduled maintenance.</h1>

  <p>
    We'll be back shortly! In the meantime, click the logo below to join us on the community Discord.
  </p>

  <NuxtLink class="discord" external href="https://discord.gg/3gDXwZ8pG9" target="_blank">
    <TeamfightIcon color="white" name="discord" size="6.2rem"/>
  </NuxtLink>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
p {
  font-size: 1.6rem
}

.discord {
  fill: white;
  transition: fill 0.25s ease;

  &:hover {
    fill: var(--Color-Orange);
  }
}
</style>