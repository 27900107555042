<template>
  <Notifications classes="teamfight-notification" group="error" width="40rem">
    <template #body="props">
      <TeamfightNotification
          :CTA="props.item.data.CTA"
          :link="props.item.data.link"
          :onClose="() => props.item.data.onClose ? props.item.data.onClose(props.close) : props.close()"
          :text="props.item.text"
          :title="props.item.title"
          :type="props.item.type"
          icon="bugAlt"
      />
    </template>
  </Notifications>

  <Notifications classes="teamfight-notification" position="bottom right" width="40rem">
    <template #body="props">
      <TeamfightNotification
          :CTA="props.item.data.CTA"
          :icon="props.item.data.icon"
          :link="props.item.data.link"
          :onClose="() => props.item.data.onClose ? props.item.data.onClose(props.close) : props.close()"
          :text="props.item.text"
          :title="props.item.title"
          :type="props.item.type"
      />
    </template>
  </Notifications>
</template>

<script lang="ts" setup>
</script>