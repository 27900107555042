<template>
  <ViewWrapper :backdropImage="getCDNImageUrl('/TFTSet3_Wallpaper.jpeg')" hideAd>
    <div class="home">
      <TeamfightLogo :size="md ? 6 : 4"/>

      <div class="search">
        <SummonerSearch :size="sm ? 'full': 'minimal'" customBaseStyle="overflow-y-auto max-h-[30vh]"/>
      </div>
    </div>
  </ViewWrapper>
</template>

<script lang="ts" setup>
import { getCDNImageUrl } from "~/utils/cdn";

const { sm, md } = useScreen();

definePageMeta({
  name: getPageNames().ROUTE_HOME_NAME
});

useSeoMeta({
  title: "Home"
});
</script>

<style lang="scss" scoped>
.home {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin-bottom: 9rem;
  margin-top: 14vmax;

  @include breakpoint('md') {
    gap: 4rem;
    margin-top: unset;
    justify-content: center;
  }

  .search {
    width: 100%;
    max-width: 50rem;
  }
}
</style>
